import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Box from '@mui/material/Box'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Stack from '@mui/material/Stack'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { darken, styled, useTheme } from '@mui/material/styles'
import { useState } from 'react'
import { RenderReaction } from './ChatMessageText'
import { REACTIONS } from './REACTIONS'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'

export const PaperTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
    disableInteractive={false}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '100%',
    backgroundColor: darken(theme?.palette.background.paper, 0.1)
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: darken(theme?.palette.background.paper, 0.1)
  }
}))

export const ReactionPicker = ({
  onSelected
}: {
  onSelected: (value: string) => any
}) => {
  const [state, setState] = useState({ open: false, hovering: false, index: 0 })
  const theme = useTheme()

  const handleOpen = () => setState(s => ({ ...s, open: true }))
  const handleClose = () => setState(s => ({ ...s, open: false }))
  const handleSelected = (value: string) => {
    onSelected(value)
    handleClose()
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <PaperTooltip
          open={state.open}
          onClose={handleClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement={'top-end'}
          title={
            <Stack direction='row' spacing={1}>
              {REACTIONS?.map(it => (
                <Box
                  key={it.id}
                  sx={{
                    cursor: 'pointer',
                    padding: 1,
                    borderRadius: 1,
                    '&:hover': {
                      background: darken(theme?.palette.background.paper, 0.2)
                    }
                  }}
                  onClick={() => handleSelected(it.id)}
                >
                  <RenderReaction id={it.id} size={30} />
                </Box>
              ))}
            </Stack>
          }
        >
          <RcIconButton
            disableRipple
            onClick={handleOpen}
            size='small'
            icon={
              [
                ['fad', 'smile-beam'],
                ['fad', 'smile'],
                ['fad', 'grin-squint-tears'],
                ['fad', 'kiss-beam'],
                ['fad', 'grin-beam-sweat'],
                ['fad', 'grin-hearts']
              ][state.index] as IconProp
            }
            IconProps={{
              style: {
                color: state.hovering
                  ? theme.palette.text.primary
                  : theme.palette.text.secondary,
                transform: state.index === 1 ? 'rotate(180deg)' : ''
              }
            }}
            onMouseEnter={() =>
              setState(s => ({
                ...s,
                hovering: true,
                index: (s.index + 1) % 6
              }))
            }
            onMouseLeave={() => setState(s => ({ ...s, hovering: false }))}
          />
        </PaperTooltip>
      </Box>
    </ClickAwayListener>
  )
}
