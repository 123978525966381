import { EntityOptions, useQueryEntity } from '../useEntity'
import { useFeatures } from '@/components/providers/site/FeatureProvider'

export const useTenorGifs = (options?: EntityOptions) => {
  const { cfgTenorApiKey } = useFeatures()

  const entity = useQueryEntity({
    key: 'useTenorGifs',
    ...options,
    request: { ...options?.request, idOrKey: options?.idOrKey },
    read: req =>
      fetch(
        `https://tenor.googleapis.com/v2/search?q=${req.idOrKey}&key=${cfgTenorApiKey}&limit=18`
      )
        .then(res => res.json())
        .then(it => ({ ...it, content: it.results }))
  })

  // https://developers.google.com/tenor/guides/quickstart#share
  // Share events help Tenor's Search Engine AI tune its search results
  const share = (id: string) =>
    fetch(
      `https://tenor.googleapis.com/v2/registershare?id=${id}&key=${cfgTenorApiKey}&q=${options?.idOrKey}`
    )

  return { ...entity, gifs: entity.flat, share }
}

export const useFeaturedTenorGifs = (options?: EntityOptions) => {
  const { cfgTenorApiKey } = useFeatures()

  const entity = useQueryEntity({
    key: 'useFeaturedTenorGifs',
    ...options,
    request: {},
    read: () =>
      fetch(
        `https://tenor.googleapis.com/v2/categories?key=${cfgTenorApiKey}&limit=8`
      )
        .then(res => res.json())
        .then(it => ({ ...it, content: it.tags }))
  })

  return { ...entity, featuredGifs: entity.flat }
}
