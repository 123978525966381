import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Grid from '@mui/material/Grid'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useRef, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { PaperTooltip } from './ReactionPicker'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import {
  useFeaturedTenorGifs as useTenorCategories,
  useTenorGifs
} from '@/entity/chat/useTenorGifs'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

interface TenorProps {
  onSelected: (value: string) => any
}

export const TenorPicker = ({ onSelected }: TenorProps) => {
  const [state, setState] = useState({ open: false, hovering: false, index: 0 })

  const handleOpen = () => setState(s => ({ ...s, open: true }))
  const handleClose = () => setState(s => ({ ...s, open: false }))
  const handleSelected = (value: string) => {
    onSelected(value)
    handleClose()
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <PaperTooltip
          open={state.open}
          onClose={handleClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          placement={'top-end'}
          title={<TenorSearch onSelected={handleSelected} />}
        >
          <RcIconButton
            disableRipple
            onClick={handleOpen}
            icon={['fad', 'images']}
            size='small'
          />
        </PaperTooltip>
      </Box>
    </ClickAwayListener>
  )
}

export const TenorSearch = ({ onSelected }: TenorProps) => {
  const [searchTerm, setSearchTerm] = useState('')
  const isMobile = useIsMobile()

  const inputRef = useRef<HTMLInputElement>(null)
  const [input, setInput] = useState('')
  const [debouncedInput] = useDebounce(input, 200)
  useEffect(() => {
    setSearchTerm(debouncedInput)
  }, [debouncedInput])

  return (
    <Box width={isMobile ? '90vw' : 400}>
      <Stack direction='row' sx={{ padding: 2 }}>
        {searchTerm ? (
          <RcIconButton
            icon={['fal', 'arrow-left']}
            onClick={() => {
              setSearchTerm('')
              setInput('')
            }}
          />
        ) : null}
        <TextField
          ref={inputRef}
          placeholder='Search Tenor'
          value={input}
          onChange={evt => setInput(evt.target.value)}
          fullWidth
        />
      </Stack>
      <Box height={400} p={2} overflow={'auto'}>
        <RcSuspense height={400}>
          {searchTerm ? (
            <TenorDisplay
              searchTerm={searchTerm}
              onSelected={val => onSelected(val)}
            />
          ) : (
            <TenorCategories
              onSelected={val => {
                setSearchTerm(val)
                setInput(val)
                inputRef.current?.focus()
              }}
            />
          )}
        </RcSuspense>
      </Box>
    </Box>
  )
}

export const TenorCategories = ({ onSelected }: TenorProps) => {
  const { featuredGifs } = useTenorCategories()

  return (
    <Grid container direction='row' spacing={3}>
      {featuredGifs.map(it => (
        <Grid key={it.media_formats?.tinygif?.url || it.image} item xs={6}>
          <Card>
            <CardMedia
              onClick={() => onSelected(it.searchterm)}
              image={it.media_formats?.tinygif?.url || it.image || ''}
              sx={{ height: 125, cursor: 'pointer' }}
            >
              <Typography>{it.name}</Typography>
            </CardMedia>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}

export const TenorDisplay = ({
  searchTerm,
  onSelected
}: TenorProps & {
  searchTerm: string
}) => {
  const { gifs, share } = useTenorGifs({ idOrKey: searchTerm })

  const handleSelected = (it: any) => {
    share(it.id)
    onSelected(it.media_formats?.gif?.url)
  }

  return (
    <ImageList variant='masonry' cols={2} gap={8}>
      {gifs.map(it => (
        <ImageListItem
          component={Card}
          key={it.media_formats?.gif?.url}
          onClick={() => handleSelected(it)}
          sx={{
            width: '100%',
            minHeight: 100,
            cursor: 'pointer'
          }}
        >
          <img
            src={it.media_formats?.gif?.url || ''}
            srcSet={it.media_formats?.gif?.url || ''}
            alt={it.title}
          />
        </ImageListItem>
      ))}
    </ImageList>
  )
}
