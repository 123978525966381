import profanity from '@2toad/profanity/dist/profanity'
import { ChatMessageControllerApi } from '@rallycry/social-api-typescript/dist/apis/ChatMessageControllerApi'
import {
  DocumentData,
  QueryDocumentSnapshot,
  collection,
  orderBy as firestoreOrder,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
  where
} from 'firebase/firestore'
import { clone, first, orderBy, some, takeRight, uniqBy } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useUserAccount } from '../user/useUserAccount'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useImpersonation } from '@/components/providers/site/ImpersonationProvider'
import { useTime } from '@/core/hooks/useTime'
import { useSocialController } from '@/core/hooks/useSWRApi'

// static values for lifetime of app run
const PAGE_SIZE = 10

export interface ChatMessage {
  uid: string
  text: string
  dateCreated: Date
  author: number
}

const mapResult = (doc: QueryDocumentSnapshot<DocumentData>) => ({
  snapshot: doc,
  ...(doc.data() as ChatMessage),
  uid: doc.id
})

export const useChatMessages = (
  options: EntityOptions & { shouldSubscribe?: boolean; limit?: number }
) => {
  const {
    filterProfanity,
    featChatProfanityFilter,
    cfgChatAllowedDomains,
    featChatGifs
  } = useFeatures()
  const { user } = useUserAccount()
  const { isProfile, impersonation } = useImpersonation()
  const { firestore } = useFirebase()
  const { getNow } = useTime()
  const { ctrl } = useSocialController(ChatMessageControllerApi)

  const now = useMemo(() => {
    return getNow().toDate()
  }, [getNow])

  const collectionKey = `Social_Chat/${options.idOrKey}/messages`
  const db = firestore

  // load up previous messages (without subscription) in batches for before "now"
  // messages before "now" will not receive edits / deletes.
  const messages = useReadEntity({
    key: 'useChatMessages' + options.limit,
    ...options,
    read: async () => {
      const c = collection(db, collectionKey)
      const q = query(
        c,
        firestoreOrder('dateCreated', 'desc'),
        where('dateCreated', '<', now.getTime()),
        where('unsent', '==', false),
        limit(options.limit || 20)
      )
      return getDocs(q).then(res => {
        return {
          content: orderBy(
            res.docs.map(it => mapResult(it)),
            'dateTouched',
            'asc'
          )
        }
      })
    }
  })

  const mutate = messages.read.mutate
  const merge = useCallback(
    (additions: any[], updates: any[], removals: any[]) => {
      const removedIds = removals?.map(it => it.uid)

      // mutate the first page of SWRInfinite cache to insert new document(s)
      mutate((data: any) => {
        const firstPage = data
        if (firstPage && firstPage.content) {
          const updated = uniqBy(
            orderBy(
              [...updates, ...firstPage.content, ...additions],
              'dateTouched',
              'desc'
            ),
            it => it.uid
          )
          const sorted = orderBy(updated, 'dateCreated')
          const filtered = sorted.filter((it, _, arr) => {
            return (
              !removedIds.includes(it.uid) &&
              // remove temp messages when real message arrives
              (it.uid !== 'temp' ||
                !arr.find(a => a.uid !== 'temp' && a.text === it.text))
            )
          })
          firstPage.content = filtered
        }
        // return a new reference to trigger react re-render
        return clone(firstPage)
      }, false)
    },
    [mutate]
  )

  // listen for new messages / updates for anything after "now"
  useEffect(() => {
    if (!options.shouldSubscribe) return
    const c = collection(db, collectionKey)
    const q = query(c, where('dateTouched', '>=', now.getTime()))
    const unsubscribe = onSnapshot(q, data => {
      const additions = data
        .docChanges()
        .filter(it => it.type === 'added')
        .map(it => mapResult(it.doc))

      const updates = data
        .docChanges()
        .filter(it => it.type === 'modified' && !it.doc.data().unsent)
        .map(it => mapResult(it.doc))

      const removals = data
        .docChanges()
        .filter(it => it.doc.data().unsent)
        .map(it => mapResult(it.doc))

      merge(additions, updates, removals)
    })
    return () => unsubscribe()
  }, [now, db, collectionKey, merge, options.shouldSubscribe])

  // load more based on cursor of oldest message. return true/false if hasMore
  const loadMore = useCallback(async () => {
    const cursor = first(messages.read.data?.content!)?.snapshot

    if (!cursor) return false

    const c = collection(db, collectionKey)

    const q = query(
      c,
      firestoreOrder('dateCreated', 'desc'),
      where('unsent', '==', false),
      startAfter(cursor),
      limit(PAGE_SIZE)
    )
    const res = await getDocs(q)

    const additions = res.docs.map(it => mapResult(it))
    merge(additions, [], [])

    return some(additions)
  }, [db, messages, merge, collectionKey])

  const postMessage = async (message: string) => {
    // optimisically mutate the first page of SWRInfinite cache to insert temp document
    mutate((data: any) => {
      const firstPage = data
      if (firstPage && firstPage.content) {
        firstPage.content = uniqBy(
          orderBy(
            [
              ...firstPage.content,
              ...[
                {
                  uid: 'temp',
                  text: message,
                  author: user?.id!,
                  dateCreated: getNow().toDate().getTime(),
                  dateTouched: getNow().toDate().getTime()
                } as any
              ]
            ],
            'dateTouched',
            'asc'
          ),
          it => it.uid
        )
      }
      // return a new reference to trigger react re-render
      return clone(firstPage)
    }, false)
    await ctrl({
      // skip impersonate in chat for admins impersonating a user
      skipImpersonation: !!impersonation && !isProfile
    }).createChatMessage({
      chatId: options.idOrKey?.toString()!,
      chatMessageCreateCommand: { text: message }
    })
  }

  const updateMessage = useCallback(
    async (doc: ChatMessage, message: string) => {
      merge([], [{ ...doc, text: message }], [])
      await ctrl().updateChatMessage({
        chatId: options.idOrKey?.toString()!,
        id: doc.uid,
        chatMessageUpdateCommand: { text: message }
      })
    },
    //  eslint-disable-next-line react-hooks/exhaustive-deps
    [merge, collectionKey, options.idOrKey]
  )

  const deleteMessage = useCallback(
    async (uid: string) => {
      merge([], [], [{ uid }])
      await ctrl().unsendChatMessage({
        chatId: options.idOrKey?.toString()!,
        id: uid
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [merge, collectionKey, options.idOrKey /* ctrl */]
  )

  const filtered = useMemo(() => {
    // comma separated allowed top-level domains, with optional subdomains
    const cfgDomains = (cfgChatAllowedDomains as string).split(',')
    cfgDomains.push('rallycry.gg') // always allow
    cfgDomains.push('rallycryapp.com') // always allow
    cfgDomains.push('cultofthepartyparrot.com') // gotta have the party parrots
    featChatGifs && cfgDomains.push('tenor.com')

    const allowedDomains = cfgDomains
      .map(it => `(\[a-zA-Z0-9-]+\\.)*` + it.replace('.', `\.`).trim())
      .join('|')

    // match domain-like strings excluding allowed top level domains
    // eg. not-allowed.com
    const domainRegex = `(?!${allowedDomains})[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{2,9}`

    // match ip address as top level domain
    // e.g.  1.1.1.1
    const ipRegex = `(?:[0-9]{1,3}\\.){3}[0-9]{1,3}`

    // smash it all together and capture the url scheme (http/https/ftp) and optional path at the end
    const combined = new RegExp(
      `\\b((http|ftp)s?:\\/\\/)?(${domainRegex}|${ipRegex})(\\/[^\\s]*)+\\b`,
      'i'
    )

    return (
      messages.read.data?.content?.map(it => {
        let text = it.text

        if (featChatProfanityFilter || filterProfanity)
          text = profanity.censor(it.text)

        // filter redacted urls
        text = text.replace(combined, 'redacted')

        return { ...it, text }
      }) || []
    )
  }, [
    featChatProfanityFilter,
    filterProfanity,
    featChatGifs,
    cfgChatAllowedDomains,
    messages.read.data?.content
  ])

  const prune = useCallback(() => {
    mutate((data: any) => {
      const firstPage = data
      if (firstPage && firstPage.content) {
        firstPage.content = takeRight(firstPage.content, 10)
      }
      // return a new reference to trigger react re-render
      return clone(firstPage)
    })
  }, [mutate])

  return {
    ...messages,
    messages: filtered,
    postMessage,
    loadMore,
    updateMessage,
    deleteMessage,
    prune
  }
}
