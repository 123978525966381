import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { sortBy } from 'lodash-es'
import { MemberKind } from '@rallycry/social-api-typescript'
import { ChatRoomProps } from './ChatRoom'
import { ActionMenuOption } from '@/components/molecules/interactive/ActionMenu'
import { AvatarText } from '@/components/molecules/text/AvatarText'
import { RootRoute } from '@/core/route-keys'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useChat } from '@/components/providers/site/ChatProvider'
import { useIsMdUp } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'

export const useChatRoomActions = (
  props: ChatRoomProps,
  chatRoomDocument: ReturnType<typeof useChatRoomDocument>
) => {
  const isMdUp = useIsMdUp()
  const { navTo } = useNavigation()
  const { handleShowChat } = useChat()
  const { filterProfanity, setFilterProfanity, featChatProfanityFilter } =
    useFeatures()

  const { chatId, onNavigate, onLeave, allowExpand, allowDocking } = props
  const {
    members,
    leaveChat,
    attributes: { entityId, entityType, childId, childType }
  } = chatRoomDocument

  const options: ActionMenuOption[] = []

  const getViewEntity = () =>
    childType === EntityType.BRACKETMATCH ? (
      <RcTrans i18nKey='shared.view-match' />
    ) : entityType === EntityType.COMMUNITY ? (
      <RcTrans i18nKey='shared.view-community' />
    ) : entityType === EntityType.ACTIVITY ? (
      <RcTrans i18nKey='shared.view-party' />
    ) : (
      <RcTrans i18nKey='shared.view-competition' />
    )

  const handleOnNavigate = async () => {
    onNavigate?.()
    navTo({
      root:
        entityType === EntityType.COMMUNITY
          ? RootRoute.Community
          : entityType === EntityType.ACTIVITY
          ? RootRoute.PartyFinder
          : entityType === EntityType.COMPETITION
          ? RootRoute.Competition
          : RootRoute.NotFound,
      rootId: entityId,
      subRoute:
        childType === EntityType.BRACKETMATCH ? RootRoute.Match : undefined,
      subId: childType === EntityType.BRACKETMATCH ? childId : undefined
    })
  }

  options.push({
    key: 'members',
    display: <RcTrans i18nKey='shared.participants' />,
    modalProps: {
      noPadding: true,
      noFooter: true,
      title: <RcTrans i18nKey='shared.participants' />
    },
    modal: () => (
      <TableContainer>
        <Table>
          <TableBody>
            {sortBy(members, it => it.name?.toLowerCase())?.map(it => (
              <TableRow key={it.id}>
                <TableCell sx={{ padding: 0 }}>
                  {it.kind === MemberKind.FIREBASEUSER ? (
                    <UserAvatar
                      user={{ ...it, id: Number(it.id) }}
                      size='xs'
                      sx={{ marginLeft: 5, marginY: 3 }}
                    />
                  ) : (
                    <Box>
                      <AvatarText
                        avatarChildren={<RcIcon icon={['fal', 'robot']} />}
                        src={it.image}
                        sx={{ marginLeft: 5, marginY: 3 }}
                      >
                        <Typography>
                          {it.name}
                          <Chip
                            color='primary'
                            label={<RcTrans i18nKey='shared.bot' />}
                            sx={{ ml: 2 }}
                          />
                        </Typography>
                      </AvatarText>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  })

  if (!featChatProfanityFilter) {
    options.push({
      key: 'profanity',
      display: filterProfanity ? (
        <RcTrans i18nKey='shared.toggle-profanity-enabled' />
      ) : (
        <RcTrans i18nKey='shared.toggle-profanity-disabled' />
      ),
      action: async () => {
        setFilterProfanity(f => !f)
      }
    })
  }

  if (onNavigate) {
    options.push({
      key: 'navigate',
      display: getViewEntity(),
      action: handleOnNavigate
    })
  }

  if (allowDocking && isMdUp) {
    options.push({
      key: 'dock',
      display: <RcTrans i18nKey='shared.dock-chat' />,
      action: async () => {
        handleShowChat(chatId)
      }
    })
  }

  if (allowExpand) {
    options.push({
      key: 'expand',
      display: <RcTrans i18nKey='shared.expand-chat' />,
      action: async () => {
        onNavigate?.()
        navTo({
          root: RootRoute.Chat,
          rootId: chatId
        })
      }
    })
  }

  // leave chat
  options.push({
    key: 'leave',
    display: <RcTrans i18nKey='shared.leave-chat' />,
    confirmation: <RcTrans i18nKey='shared.leave-chat-confirm' />,
    action: async () => {
      onLeave?.()
      await leaveChat()
    }
  })

  return options
}
