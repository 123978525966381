import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { ChatInput } from './ChatInput'
import { ChatMessageText } from './ChatMessageText'
import { useChatRoomDocument } from '@/entity/chat/useChatRoomDocument'
import { ChatMessage } from '@/entity/chat/useChatMessages'
import { useTime } from '@/core/hooks/useTime'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcTrans } from '@/components/atoms/RcTrans'

export const ChatMessageRow = ({
  chatId,
  chatMessage,
  members,
  isChatAdmin,
  isOwnChat,
  isNewDay,
  isNewUserSpeech,
  isEditing,
  onEdit,
  onUpdate,
  onDelete
}: {
  chatId: string
  chatMessage: ChatMessage
  members?: ReturnType<typeof useChatRoomDocument>['members']
  isChatAdmin?: boolean
  isOwnChat?: boolean
  isNewDay?: boolean
  isNewUserSpeech: boolean
  isEditing?: boolean
  onEdit: (isEditing: boolean) => void
  onUpdate: (message: string) => void
  onDelete: () => void
}) => {
  const { displayDate, displayDateTime, displayTime, displayRelativeTime } =
    useTime()

  const edit = (
    <Box pr={2}>
      <ChatInput
        chatId={chatId}
        existing={chatMessage.text}
        onSubmit={value => {
          onUpdate(value)
          onEdit(false)
        }}
        onCancel={() => onEdit(false)}
      />
    </Box>
  )

  const author = members?.find(it => it.id === chatMessage.author?.toString())

  const display = (
    <>
      {isNewUserSpeech ? (
        <Stack direction='row' spacing={2} alignItems='center' sx={{ mb: 1 }}>
          <Typography key={chatMessage.uid} variant='body0'>
            {author?.name}
          </Typography>
          <Tooltip title={displayDateTime(chatMessage.dateCreated)}>
            <Typography variant='subtitle2' color='text.secondary' noWrap>
              {displayRelativeTime(chatMessage.dateCreated)}
            </Typography>
          </Tooltip>
        </Stack>
      ) : null}
      <ChatMessageText
        key={chatMessage.uid}
        message={chatMessage.text}
        isTemp={chatMessage.uid === 'temp'}
      />
      {isOwnChat || isChatAdmin ? (
        <Box
          position='absolute'
          right={20}
          top={-33}
          display='none'
          className='crudcontrol'
        >
          <Card>
            <Stack direction='row'>
              <RcIconButton
                icon={['fal', 'pencil']}
                size='small'
                disableRipple
                onClick={() => onEdit(true)}
              />
              <ConfirmingButton
                icon={['fal', 'trash']}
                size='small'
                disableRipple
                onClick={onDelete}
                message={<RcTrans i18nKey='error.confirmation.chat-message' />}
                buttonName={<RcTrans i18nKey='shared.delete' />}
              />
            </Stack>
          </Card>
        </Box>
      ) : null}
    </>
  )

  return (
    <>
      {isNewDay ? (
        <Grid
          container
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{ mt: 3 }}
        >
          <Grid item xs>
            <Divider />
          </Grid>
          <Grid item>
            <Typography variant='subtitle2' color='text.secondary'>
              {displayDate(chatMessage.dateCreated)}
            </Typography>
          </Grid>
          <Grid item xs>
            <Divider />
          </Grid>
        </Grid>
      ) : null}
      <Stack
        id={`msg-${chatMessage.uid}`}
        key={chatMessage.uid}
        direction='row'
        sx={{
          padding: 1,
          marginTop: isNewUserSpeech ? 5 : 0,
          backgroundColor: 'transparent',
          ['&:hover']: {
            backgroundColor: 'rgba(0,0,0,.08)',
            '& .timeDisplay': { color: 'text.secondary' },
            '& .crudcontrol': { display: 'inherit' }
          }
        }}
        spacing={2}
      >
        <Box
          minWidth={36}
          display='flex'
          alignItems='flex-start'
          justifyContent='flex-end'
        >
          {isNewUserSpeech ? (
            <RcAvatar src={author?.image} sx={{ width: 28, height: 28 }}>
              {author?.name?.charAt(0)?.toLocaleUpperCase()}
            </RcAvatar>
          ) : (
            <Tooltip title={displayDateTime(chatMessage.dateCreated)}>
              <Typography
                variant='subtitle2'
                sx={{
                  marginTop: '5px',
                  color: 'transparent',
                  userSelect: 'none',
                  textOverflow: 'unset'
                }}
                noWrap
                className='timeDisplay'
              >
                {displayTime(chatMessage.dateCreated, true)}
              </Typography>
            </Tooltip>
          )}
        </Box>

        <Box position='relative' width='100%' sx={{ wordBreak: 'break-all' }}>
          {isEditing ? edit : display}
        </Box>
      </Stack>
    </>
  )
}
